import React from 'react';
import { Link } from 'react-router-dom';

import {
  CalendarMonthRounded,
  CheckRounded,
  Image,
  NotificationsRounded,
  ReportProblemRounded
} from '@mui/icons-material';

import Label from '../Label/Label';
import Timeline from '../Timeline/Timeline';

const QUOTING = 'quoting';
const SAMPLING = 'sampling';
const ORDERED = 'ordered';

const statuses = {
  variant: {
    'action-required': 'caution',
    'in-progress': 'success',
    'new-update': 'alert'
  },
  icon: {
    'action-required': <ReportProblemRounded fontSize="14" />,
    'in-progress': <CheckRounded fontSize="14" />,
    'new-update': <NotificationsRounded fontSize="14" />
  },
  text: {
    'action-required': 'Action Required',
    'in-progress': 'In Progress',
    'new-update': 'New Update'
  }
};

const ProductCard = ({
  href = '#',
  imgSrc,
  status = 'in-progress',
  name,
  stage = 0,
  stageLabels = [QUOTING, SAMPLING, ORDERED],
  /* milestone, */
  style = {}
}) => {
  const variant = status && statuses.variant[status];
  const icon = status && statuses.icon[status];
  const text = status && statuses.text[status];

  return (
    <Link
      to={href}
      className="flex flex-col items-start justify-start bg-white rounded-2xl shadow-lg p-3 min-w-full lg:max-w-[18rem] md:max-h-[30rem] h-full"
      style={style}
    >
      <div className="relative bg-black rounded-lg w-full h-[170px]">
        {imgSrc && (
          <img
            src={imgSrc}
            alt="product"
            width="100%"
            height="100%"
            className="absolute flex items-center justify-center w-full h-full bg-[#D3D5D9] rounded-md object-cover"
          />
        )}
        {!imgSrc && (
          <div className="absolute flex items-center justify-center w-full h-full bg-[#D3D5D9] rounded-lg">
            <Image htmlColor="#6E747D" fontSize="large" />
          </div>
        )}
      </div>
      {variant && (
        <Label variant={variant} style={{ margin: '12px 0' }}>
          {icon}
          <span className="mt-0.5">{text}</span>
        </Label>
      )}
      <span className="text-md font-600">{name}</span>
      <div className="mt-3 w-full">
        <Timeline stage={stage} labels={stageLabels} />
      </div>
      {/* <div className="flex gap-1">
                <CalendarMonthRounded htmlColor="#6E747D" fontSize="small" />
                <Text small color="#6E747D">
                    Next milestone: {milestone || 'TBD'}
                </Text>
            </div> */}{' '}
      {/* This line is commented to solver issue: 1512-hide-next-milestone-date */}
    </Link>
  );
};

export default ProductCard;
