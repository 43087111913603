import React from 'react';

const Avatar = ({ src, alt = 'company logo' }) => (
  <span className="block size-12 rounded-xl overflow-hidden shrink-0 aspect-square">
    {src ? (
      <img
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        className="size-full object-cover"
      />
    ) : (
      <img
        src="/images/defaultAvatar.svg"
        alt={alt}
        width="100%"
        height="100%"
        className="size-full object-cover"
      />
    )}
  </span>
);

export default Avatar;
