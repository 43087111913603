import React from 'react';
import { Link } from 'react-router-dom';

import {
  CalendarMonthRounded,
  CheckRounded,
  Image,
  NotificationsRounded,
  ReportProblemRounded
} from '@mui/icons-material';

import getFormattedDate from 'src/utils/getFormattedDate';

import Label from '../Label/Label';
import Timeline from '../Timeline/Timeline';

const QUOTING = 'quoting';
const SAMPLING = 'sampling';
const ORDERED = 'ordered';

const statuses = {
  variant: {
    'action-required': 'caution',
    'in-progress': 'success',
    'new-update': 'alert'
  },
  icon: {
    'action-required': <ReportProblemRounded fontSize="x-small" />,
    'in-progress': <CheckRounded fontSize="x-small" />,
    'new-update': <NotificationsRounded fontSize="x-small" />
  },
  text: {
    'action-required': 'Action Required',
    'in-progress': 'In Progress',
    'new-update': 'New Update'
  }
};

const ProductItem = ({
  href = '#',
  imgSrc,
  status = 'in-progress',
  name,
  stage = 0,
  stageLabels = [QUOTING, SAMPLING, ORDERED],
  createdAt,
  style = {}
}) => {
  const variant = status && statuses.variant[status];
  const icon = status && statuses.icon[status];
  const text = status && statuses.text[status];

  return (
    <tr
      key={name}
      className="flex gap-4 items-center justify-start bg-white min-w-full p-3 overflow-x-auto"
      style={style}
    >
      <td className="flex items-center min-w-80 max-w-80">
        <Link to={href} className="truncate">
          <span className="font-500">{name}</span>
        </Link>
      </td>
      <td className="flex items-center min-w-24 max-w-24">
        <div className="relative flex items-center min-w-16 min-h-16 rounded-lg">
          {imgSrc && (
            <img
              src={imgSrc}
              alt="product"
              width="100%"
              height="100%"
              className="absolute flex items-center justify-center w-full h-[100%] bg-[#D3D5D9] rounded-md object-cover"
            />
          )}
          {!imgSrc && (
            <div className="absolute flex items-center justify-center w-full h-[100%] bg-[#D3D5D9] rounded-lg">
              <Image htmlColor="#6E747D" fontSize="large" />
            </div>
          )}
        </div>
      </td>
      <td className="flex items-center my-4 w-full max-w-[25%]">
        <Timeline stage={stage} labels={stageLabels} />
      </td>
      <td className="flex items-center gap-1 min-w-24 max-w-24">
        <span className="text-xs">{getFormattedDate(createdAt)}</span>
      </td>
      <td className="flex min-w-40 max-w-40">
        {variant && (
          <Label variant={variant}>
            {icon}
            {text}
          </Label>
        )}
      </td>
    </tr>
  );
};

export default ProductItem;
