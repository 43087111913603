import { SidebarCollapse, SidebarExpand } from 'iconoir-react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { Button, Navigation } from '@cavela/ui';
import { Add, Menu, PersonRounded, ShoppingBag } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import KeyboardTabRoundedIcon from '@mui/icons-material/KeyboardTabRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';

import { AnimatePresence, motion } from 'framer-motion';
import useFeatureFlag from 'src/hooks/useFeatureFlag';

import AuthContext from '../../context/Auth';
import useSidebarStore from './useSidebarStore';

const SidebarNav = () => {
  const { pathname } = window.location;
  const { userInfo } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { isCollapsed, toggleIsCollapsed, setScreenResizing } =
    useSidebarStore();

  const routes = [
    {
      label: 'Products',
      route: '/',
      icon: ShoppingBagRoundedIcon,
      selected: pathname === '/' || pathname.match(/products/)
    },
    {
      label: 'Profile',
      route: '/profile',
      icon: PersonRoundedIcon,
      selected: pathname.match(/profile/)
    }
  ];

  return (
    <>
      <div className="lg:hidden absolute ml-10 mt-4">
        {isMenuOpen ? (
          <button
            onClick={toggleMenu}
            className="fixed bottom-4 left-4 bg-[#030FEF] rounded-lg p-2 z-50"
          >
            <Menu htmlColor="white" />
          </button>
        ) : (
          <button
            onClick={toggleMenu}
            className="fixed bottom-4 left-4 bg-[#030FEF] rounded-lg p-2 z-10"
          >
            <Menu htmlColor="white" />
          </button>
        )}
      </div>

      <motion.div
        initial={{ width: isCollapsed ? 52 : 236 }}
        animate={{ width: isCollapsed ? 52 : 236 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        onAnimationStart={() => setScreenResizing(true)}
        onAnimationComplete={() => setScreenResizing(false)}
        className="h-full pl-4 py-4 hidden md:flex gap-3 flex-col justify-between overflow-hidden text-base select-none cursor-pointer"
        onClick={() => toggleIsCollapsed()}
      >
        <div className="flex flex-col gap-3">
          <div
            className="w-full h-10 flex gap-1 items-center cursor-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="size-9 shrink-0 rounded-md">
              {userInfo?.avatar ? (
                <img
                  src={userInfo.avatar}
                  alt="avatar"
                  className="size-full object-cover"
                />
              ) : (
                <img
                  src="/images/defaultAvatar.svg"
                  alt="avatar"
                  className="size-full object-cover"
                />
              )}
            </div>
            <div className="w-full flex flex-col truncate select-text">
              <span className="truncate text-black font-500">
                {userInfo?.username}
              </span>
              <span className="truncate text-light-gray font-400">
                {userInfo?.email}
              </span>
            </div>
          </div>

          {routes.map((route) => (
            <Link
              key={route.label}
              to={route.route}
              className={`h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer ${route.selected ? 'bg-gray-4' : ''}`}
              data-tooltip-id={route.label}
              data-tooltip-content={route.label}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="size-9 flex items-center justify-center shrink-0">
                <route.icon
                  className="text-black"
                  style={{
                    fontSize: 20
                  }}
                />
              </div>

              <span className="text-black font-500">{route.label}</span>
            </Link>
          ))}

          <Link
            to="/new-rfq"
            className="h-9 w-full flex items-center justify-center bg-p-cavela-blue rounded-full overflow-hidden cursor-pointer"
            data-tooltip-id="New Product"
            data-tooltip-content="New Product"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="size-9 flex items-center justify-center shrink-0">
              <AddRoundedIcon
                className="text-white"
                style={{
                  fontSize: 20
                }}
              />
            </div>

            <motion.span
              className="whitespace-nowrap text-white font-c font-700"
              initial={{
                width: isCollapsed ? 0 : 'auto',
                marginRight: isCollapsed ? 0 : 14
              }}
              animate={{
                width: isCollapsed ? 0 : 'auto',
                marginRight: isCollapsed ? 0 : 14
              }}
            >
              New Product
            </motion.span>
          </Link>
        </div>

        <div
          className="size-9 cursor-pointer flex items-center justify-center justify-self-end"
          onClick={(e) => {
            toggleIsCollapsed();
            e.stopPropagation();
          }}
        >
          {/* <motion.div
            className="size-full flex items-center justify-center"
            initial={{ rotateZ: isCollapsed ? 0 : 180 }}
            animate={{ rotateZ: isCollapsed ? 0 : 180 }}
            transition={{ duration: 0.1 }}
          >
            <KeyboardTabRoundedIcon
              className="text-black"
              style={{
                fontSize: 20
              }}
            />
          </motion.div> */}
          <AnimatePresence>
            {isCollapsed ? (
              <motion.div
                key="expanded"
                className="absolute"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <SidebarExpand color="black" />
              </motion.div>
            ) : (
              <motion.div
                key="collapsed"
                className="absolute"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <SidebarCollapse color="black" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      <AnimatePresence>
        {isMenuOpen ? (
          <motion.div
            initial={{ x: -252 }}
            animate={{ x: 0 }}
            exit={{ x: -252 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 w-[252px] h-full z-40 p-4 flex md:hidden gap-3 flex-col overflow-hidden text-base bg-white"
          >
            <div className="w-full h-10 flex gap-1 items-center">
              <div className="size-9 shrink-0 rounded-md">
                {userInfo?.avatar ? (
                  <img
                    src={userInfo.avatar}
                    className="size-full object-cover"
                  />
                ) : (
                  <img
                    src="/images/defaultAvatar.svg"
                    className="size-full object-cover"
                  />
                )}
              </div>
              <div className="w-full flex flex-col truncate">
                <span className="truncate text-black font-500">
                  {userInfo?.username}
                </span>
                <span className="truncate text-light-gray font-400">
                  {userInfo?.email}
                </span>
              </div>
            </div>

            {/* <Link
              to="/"
              className="h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer"
            >
              <div className="size-9 flex items-center justify-center shrink-0">
                <ShoppingBagRoundedIcon
                  className="text-black"
                  style={{
                    fontSize: 20
                  }}
                />
              </div>

              <span className="text-black font-500">Products</span>
            </Link>

            <Link
              to="/profile"
              className="h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer"
            >
              <div className="size-9 flex items-center justify-center shrink-0">
                <PersonRoundedIcon
                  className="text-black"
                  style={{
                    fontSize: 20
                  }}
                />
              </div>

              <span className="text-black font-500">Account</span>
            </Link> */}

            {routes.map((route) => (
              <Link
                to={route.route}
                className={`h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer ${route.selected ? 'bg-gray-4' : ''}`}
              >
                <div className="size-9 flex items-center justify-center shrink-0">
                  <route.icon
                    className="text-black"
                    style={{
                      fontSize: 20
                    }}
                  />
                </div>

                <span className="text-black font-500">{route.label}</span>
              </Link>
            ))}

            <Link
              to="/new-product"
              className="h-9 w-full flex items-center justify-center bg-p-cavela-blue rounded-full overflow-hidden cursor-pointer"
            >
              <div className="size-9 flex items-center justify-center shrink-0">
                <AddRoundedIcon
                  className="text-white"
                  style={{
                    fontSize: 20
                  }}
                />
              </div>

              <span className="whitespace-nowrap text-white font-c font-700 w-auto mr-[14px]">
                New Product
              </span>
            </Link>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <div className="z-50">
        {isCollapsed && routes.map((route) => <Tooltip id={route.label} />)}
        {isCollapsed && <Tooltip id="New Product" />}
      </div>
    </>
  );
};

export default SidebarNav;
